export default {
  UPDATE_STATE_HOME: 'UPDATE_STATE_HOME',
  UPDATE_STATE_APPS: 'UPDATE_STATE_APPS',
  UPDATE_STATE_APPCATEGORY: 'UPDATE_STATE_APPCATEGORY',
  UPDATE_STATE_MOVIES: 'UPDATE_STATE_MOVIES',
  UPDATE_STATE_MOVIECATEGORY: 'UPDATE_STATE_MOVIECATEGORY',
  UPDATE_STATE_TVSHOWS: 'UPDATE_STATE_TVSHOWS',
  UPDATE_STATE_TVSHOWCATEGORY: 'UPDATE_STATE_TVSHOWCATEGORY',
  UPDATE_STATE_GAMES: 'UPDATE_STATE_GAMES',
  UPDATE_STATE_MUSIC: 'UPDATE_STATE_MUSIC',
  UPDATE_STATE_SEARCH: 'UPDATE_STATE_SEARCH',
  UPDATE_STATE_SETTINGS: 'UPDATE_STATE_SETTINGS',
  UPDATE_STATE_FILTERS: 'UPDATE_STATE_FILTERS',
  UPDATE_STATE_MYAPPS: 'UPDATE_STATE_MYAPPS'
};
