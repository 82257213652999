'use strict'; //todo: include Foxxum license here.

var keymap = {
  // Enter
  VK_ENTER: 13,
  // Arrows
  VK_LEFT: 37,
  VK_UP: 38,
  VK_RIGHT: 39,
  VK_DOWN: 40,
  // Space and BackSpace
  VK_SPACE: 32,
  VK_BACK_SPACE: 8,
  // Numbers
  VK_0: 48,
  VK_1: 49,
  VK_2: 50,
  VK_3: 51,
  VK_4: 52,
  VK_5: 53,
  VK_6: 54,
  VK_7: 55,
  VK_8: 56,
  VK_9: 57,
  // Letters
  VK_A: 65,
  VK_B: 66,
  VK_C: 67,
  VK_D: 68,
  VK_E: 69,
  VK_F: 70,
  VK_G: 71,
  VK_H: 72,
  VK_I: 73,
  VK_J: 74,
  VK_K: 75,
  VK_L: 76,
  VK_M: 77,
  VK_N: 78,
  VK_O: 79,
  VK_P: 80,
  VK_Q: 81,
  VK_R: 82,
  VK_S: 83,
  VK_T: 84,
  VK_U: 85,
  VK_V: 86,
  VK_W: 87,
  VK_X: 88,
  VK_Y: 89,
  VK_Z: 90,
  // Colored
  VK_RED: 403,
  VK_GREEN: 404,
  VK_YELLOW: 405,
  VK_BLUE: 406,
  // Media
  VK_HELP: 156,
  VK_SEARCH: 112,
  VK_AUDIODESCRIPTION: 113,
  VK_HD: 114,
  VK_PLAY: 415,
  VK_PAUSE: 19,
  VK_PLAY_PAUSE: 179,
  VK_STOP: 413,
  VK_PREV: 424,
  VK_NEXT: 425,
  VK_FAST_FWD: 417,
  VK_REWIND: 412,
  VK_INFO: 457,
  VK_SUBTITLE: 460,
  VK_BACK: 461,
  VK_VOLUME_UP: 447,
  VK_VOLUME_DOWN: 448,
  VK_MUTE: 449,
  VK_HOME: 36,
  VK_END: 35,
  // Computer
  VK_LSHIFT: 160,
  VK_RSHIFT: 161,
  VK_LCONTROL: 162,
  VK_RCONTROL: 163,
  init: function init(platform) {
    if (!platform) platform = 'unknown';
    keymap.loadPlatform(platform);
  },
  loadPlatform: function loadPlatform(platform) {
    switch (platform) {
      // navigation keys per platform
      case 'samsung':
        var keys = new Common.API.TVKeyValue(); // navigation keys

        this.VK_DOWN = keys.KEY_DOWN;
        this.VK_UP = keys.KEY_UP;
        this.VK_LEFT = keys.KEY_LEFT;
        this.VK_RIGHT = keys.KEY_RIGHT; // numeric keys

        this.VK_0 = keys.KEY_0;
        this.VK_1 = keys.KEY_1;
        this.VK_2 = keys.KEY_2;
        this.VK_3 = keys.KEY_3;
        this.VK_4 = keys.KEY_4;
        this.VK_5 = keys.KEY_5;
        this.VK_6 = keys.KEY_6;
        this.VK_7 = keys.KEY_7;
        this.VK_8 = keys.KEY_8;
        this.VK_9 = keys.KEY_9; // playback keys

        this.VK_PLAY = keys.KEY_PLAY;
        this.VK_STOP = keys.KEY_STOP;
        this.VK_PAUSE = keys.KEY_PAUSE;
        this.VK_FAST_FWD = keys.KEY_FF;
        this.VK_REWIND = keys.KEY_RW; // other keys

        this.VK_ENTER = keys.KEY_ENTER;
        this.VK_INFO = keys.KEY_INFOLINK;
        this.VK_BACK = keys.KEY_RETURN;
        this.VK_BACK_SPACE = keys.KEY_RETURN;
        break;

      case 'tizen':
        // other keys
        this.VK_BACK = 10009;
        this.VK_BACK_SPACE = 10009;
        break;

      case 'firetv':
        // navigation keys
        this.VK_DOWN = typeof VK_DOWN === 'undefined' ? 40 : VK_DOWN;
        this.VK_UP = typeof VK_UP === 'undefined' ? 38 : VK_UP;
        this.VK_LEFT = typeof VK_LEFT === 'undefined' ? 37 : VK_LEFT;
        this.VK_RIGHT = typeof VK_RIGHT === 'undefined' ? 39 : VK_RIGHT; // playback keys

        this.VK_PLAY_PAUSE = typeof VK_PLAY_PAUSE === 'undefined' ? 179 : VK_PLAY_PAUSE;
        this.VK_FAST_FWD = typeof VK_FAST_FWD === 'undefined' ? 228 : VK_FAST_FWD;
        this.VK_REWIND = typeof VK_REWIND === 'undefined' ? 227 : VK_REWIND; // other keys

        this.VK_ENTER = typeof VK_ENTER === 'undefined' ? 13 : VK_ENTER;
        this.VK_MENU = typeof VK_MENU === 'undefined' ? 18 : VK_MENU;
        this.VK_BACK = typeof VK_BACK === 'undefined' ? 27 : VK_BACK;
        break;

      case 'ee':
        // navigation keys
        this.VK_DOWN = typeof VK_DOWN === 'undefined' ? 40 : VK_DOWN;
        this.VK_UP = typeof VK_UP === 'undefined' ? 38 : VK_UP;
        this.VK_LEFT = typeof VK_LEFT === 'undefined' ? 37 : VK_LEFT;
        this.VK_RIGHT = typeof VK_RIGHT === 'undefined' ? 39 : VK_RIGHT; // playback keys

        this.VK_PLAY_PAUSE = typeof VK_PLAY_PAUSE === 'undefined' ? 179 : VK_PLAY_PAUSE;
        this.VK_FAST_FWD = typeof VK_FAST_FWD === 'undefined' ? 228 : VK_FAST_FWD;
        this.VK_REWIND = typeof VK_REWIND === 'undefined' ? 227 : VK_REWIND; // other keys

        this.VK_ENTER = typeof VK_ENTER === 'undefined' ? 13 : VK_ENTER;
        this.VK_INFO = typeof VK_INFO === 'undefined' ? 2500 : VK_INFO;
        this.VK_BACK = typeof VK_BACK === 'undefined' ? 27 : VK_BACK;
        this.VK_BACK_SPACE = typeof VK_BACK_SPACE === 'undefined' ? 461 : VK_BACK_SPACE;
        break;

      case 'lg_webos':
        // navigation keys
        this.VK_DOWN = typeof VK_DOWN === 'undefined' ? 40 : VK_DOWN;
        this.VK_UP = typeof VK_UP === 'undefined' ? 38 : VK_UP;
        this.VK_LEFT = typeof VK_LEFT === 'undefined' ? 37 : VK_LEFT;
        this.VK_RIGHT = typeof VK_RIGHT === 'undefined' ? 39 : VK_RIGHT; // playback keys

        this.VK_PLAY = typeof VK_PLAY === 'undefined' ? 415 : VK_PLAY;
        this.VK_STOP = typeof VK_STOP === 'undefined' ? 413 : VK_STOP;
        this.VK_PAUSE = typeof VK_PAUSE === 'undefined' ? 19 : VK_PAUSE;
        this.VK_FAST_FWD = typeof VK_FAST_FWD === 'undefined' ? 417 : VK_FAST_FWD;
        this.VK_REWIND = typeof VK_REWIND === 'undefined' ? 412 : VK_REWIND; // other keys

        this.VK_ENTER = typeof VK_ENTER === 'undefined' ? 13 : VK_ENTER;
        this.VK_INFO = typeof VK_INFO === 'undefined' ? 2500 : VK_INFO;
        this.VK_BACK = typeof VK_BACK === 'undefined' ? 461 : VK_BACK;
        this.VK_BACK_SPACE = typeof VK_BACK_SPACE === 'undefined' ? 8 : VK_BACK_SPACE;
        break;

      default:
        break;
    }
  },
  key: function key(keyCode) {
    for (var code in keymap) {
      if (keymap[code] === keyCode) {
        return code;
      }
    }

    return 'VK_NM_' + keyCode;
  }
};
export default keymap;