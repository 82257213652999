//todo: include Foxxum license here.
var focus = {
  /**
   * Registered elements map
   */
  map: {},

  /**
   * Class to use when an element is focused
   */
  focusClass: 'focus',

  /**
   * Enable animations scrolling
   */
  animate: true,
  animationSpeed: 500,
  _oldView: undefined,
  _oldElement: {
    id: undefined,
    element: undefined
  },
  _curView: undefined,
  _curElement: {
    id: undefined,
    element: undefined
  },

  /**
   * Register the elements within their handlers in the specified viewId.
   * It will override the tree branch in case that the view_name already exists.
   *
   * Example:
   *
   * focus.register({ element: ['upElement', 'rightElement', 'downElement', 'leftElement', clickFunction]}, 'view1');
   *
   * @param jsonObj The json Object containing the mapping for the elements
   * @param view_name The name to identify the elements group
   * @param override If the elements group should be override by the new jsonObj
   */
  register: function register(jsonObj, view_name, override) {
    if (!override) override = true;

    if (!override) {
      for (var view in this.map) {
        if (view === view_name) return;
      }
    }

    for (var key in jsonObj) {
      if (!this.map[view_name]) this.map[view_name] = {};
      this.map[view_name][key] = jsonObj[key];
    }

    key = null; // console.log(this.map);
  },

  /**
   * Unregister the specified ViewID from the Focus Map
   * @param viewId The view to unregister
   */
  unregister: function unregister(viewId) {
    delete this.map[viewId]; // console.log(this.map);
  },

  /**
   * Move the focus to another mapped element.
   *
   * @param elementId Element ID to move the focus to
   * @param viewId View in which the Element is, Optional but recommended
   */
  moveTo: function moveTo(elementId, viewId) {
    viewId = viewId || this._getViewsFromElement(elementId)[0] || undefined;
    if (this._curElement.id === elementId && this._curView === viewId || !viewId) return;

    if (this._curElement.id) {
      this._curElement.element.className = this._curElement.element.className.replace(new RegExp('\\b ?' + this.focusClass + '\\b', 'g'), '');
    }

    if (this._curView !== viewId) {
      this._oldView = this._curView;
      this._oldElement = this._curElement;
    }

    var nextElement = document.getElementById(elementId); // console.log("Move from", this._curElement.id, "to", elementId);

    if (!nextElement) {
      this._curElement = {
        id: undefined,
        element: undefined
      };
    } else {
      this._curElement = {
        id: elementId,
        element: nextElement
      };

      var arr = this._curElement.element.className.split(' ');

      if (arr.indexOf(this.focusClass) === -1) {
        this._curElement.element.className += (arr.length >= 1 ? arr[arr.length - 1] ? ' ' : '' : '') + this.focusClass;
      }

      arr = null; // console.log(getElementOffsetTop(this._curElement.element));

      var view_element = document.getElementById(viewId); // console.log(docel, docel.scrollHeight, window.innerHeight, docel.scrollTop);

      if (view_element) {
        if (view_element.scrollHeight > window.innerHeight) {
          if (this.animate) {
            scrollToY(getElementOffsetTop(this._curElement.element) - window.innerHeight / 2, this.animationSpeed);
          } else {
            scrollToY(getElementOffsetTop(this._curElement.element) - window.innerHeight / 2, 0);
          }
        }
      }

      view_element = null;
      this._curView = viewId; // this._curElement.element.scrollIntoView();

      nextElement = null;
    }
  },

  /**
   * Change the class that the focus system will be using.
   * The default class is 'focus'.
   *
   * @param focus_class
   */
  setFocusClass: function setFocusClass(focus_class) {
    if (typeof focus_class === 'string') {
      // console.info(
      //   'Focus class changed from "' +
      //     this.focusClass +
      //     '" to "' +
      //     focus_class +
      //     '"'
      // );
      this.focusClass = focus_class;
    }
  },

  /**
   * Moves the focus to the element and, if it exists, force a click on it.
   *
   * @param elementId
   * @param viewId
   */
  clickTo: function clickTo(elementId, viewId) {
    this.moveTo(elementId, viewId);

    if (this._curElement.id) {
      this._handleEnter();
    }
  },

  /**
   * Get the old view in which the focus was before view changing
   *
   * @returns {undefined | *} Usually a string
   */
  getOldView: function getOldView() {
    return this._oldView;
  },

  /**
   * Get the old navigation element in which the focus was before view changing
   *
   * @returns {focus._oldElement|{id, element}}
   */
  getOldNav: function getOldNav() {
    return this._oldElement;
  },

  /**
   * Get the current view in which the focus is
   *
   * @returns {undefined | *} Usually a string
   */
  getCurrView: function getCurrView() {
    return this._curView;
  },

  /**
   * Get the current navigation element in which the focus is
   *
   * @returns {focus._curElement|{id, element}}
   */
  getCurrNav: function getCurrNav() {
    return this._curElement;
  },

  /**
   * Launch the Focus UP case
   */
  toUp: function toUp() {
    this._handleUp();
  },

  /**
   * Launch the Focus RIGHT case
   */
  toRight: function toRight() {
    this._handleRight();
  },

  /**
   * Launch the Focus DOWN case
   */
  toDown: function toDown() {
    this._handleDown();
  },

  /**
   * Launch the Focus LEFT case
   */
  toLeft: function toLeft() {
    this._handleLeft();
  },

  /**
   * Launch the Focus CLICK case
   */
  click: function click() {
    this._handleEnter();
  },

  /**
   * Change the opacity to the focus element to 0
   */
  hide: function hide() {
    this._curElement.element.style.opacity = '0';
  },

  /**
   * Change the opacity to the focus element to 1
   */
  show: function show() {
    this._curElement.element.style.opacity = '1';
  },
  _handleUp: function _handleUp() {
    this._handleMove(0);
  },
  _handleRight: function _handleRight() {
    this._handleMove(1);
  },
  _handleDown: function _handleDown() {
    this._handleMove(2);
  },
  _handleLeft: function _handleLeft() {
    this._handleMove(3);
  },
  _handleEnter: function _handleEnter() {
    this._handleMove(4);
  },
  _handleMove: function _handleMove(arrayPos) {
    if (!this._curElement.id || !this._curView) return;
    var elemArray = this.map[this._curView][this._curElement.id];
    if (elemArray.length <= arrayPos) return;
    var toDo = elemArray[arrayPos];
    if (typeof toDo === 'function') toDo();else if (toDo) this.moveTo(toDo, this._curView);
    toDo = null;
    elemArray = null;
  },
  _getViewsFromElement: function _getViewsFromElement(elementId) {
    var arr = [];

    for (var view in this.map) {
      for (var elid in this.map[view]) {
        if (elementId === elid) {
          arr.push(view);
        }
      }
    }

    view = null;
    elid = null;
    return arr;
  }
};

function getElementOffsetTop(element) {
  return element.offsetTop + (element.offsetParent ? getElementOffsetTop(element.offsetParent) : 0);
}

window.requestAnimFrame = function () {
  return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
    window.setTimeout(callback, 1000 / 60);
  };
}(); // main function


function scrollToY(scrollTargetY, speed, easing) {
  // scrollTargetY: the target scrollY property of the window
  // speed: time in pixels per second
  // easing: easing equation to use
  var scrollY = window.scrollY,
      currentTime = 0;
  scrollTargetY = scrollTargetY || 0;
  speed = speed || 2000;
  easing = easing || 'easeOutSine'; // min time .1, max time .8 seconds

  var time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)); // easing equations from https://github.com/danro/easing-js/blob/master/easing.js

  var easingEquations = {
    easeOutSine: function easeOutSine(pos) {
      return Math.sin(pos * (Math.PI / 2));
    },
    easeInOutSine: function easeInOutSine(pos) {
      return -0.5 * (Math.cos(Math.PI * pos) - 1);
    },
    easeInOutQuint: function easeInOutQuint(pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }

      return 0.5 * (Math.pow(pos - 2, 5) + 2);
    }
  }; // add animation loop

  function tick() {
    currentTime += 1 / 60;
    var p = currentTime / time;
    var t = easingEquations[easing](p);

    if (p < 1) {
      window.requestAnimFrame(tick);
      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
    } else {
      // console.log('scroll done');
      window.scrollTo(0, scrollTargetY);
    }
  } // call it once to get started


  tick();
}

export default focus;