import CONSTANTS from './constants';
import * as utils from '../../utils';

const initialState = {
  /* eslint-disable no-undef */
  state_home: utils.getCookie('state_home') ? JSON.parse(utils.getCookie('state_home')) : undefined,
  /* eslint-disable no-undef */
  state_apps: utils.getCookie('state_apps') ? JSON.parse(utils.getCookie('state_apps')) : undefined,
  /* eslint-disable no-undef */
  state_appCategory: utils.getCookie('state_appCategory')
    ? JSON.parse(utils.getCookie('state_appCategory'))
    : undefined,
  /* eslint-disable no-undef */
  state_movies: utils.getCookie('state_movies') ? JSON.parse(utils.getCookie('state_movies')) : undefined,
  /* eslint-disable no-undef */
  state_movieCategory: utils.getCookie('state_movieCategory')
    ? JSON.parse(utils.getCookie('state_movieCategory'))
    : undefined,
  /* eslint-disable no-undef */
  state_tvshows: utils.getCookie('state_tvshows') ? JSON.parse(utils.getCookie('state_tvshows')) : undefined,
  /* eslint-disable no-undef */
  state_tvshowCategory: utils.getCookie('state_tvshowCategory')
    ? JSON.parse(utils.getCookie('state_tvshowCategory'))
    : undefined,
  /* eslint-disable no-undef */
  state_games: utils.getCookie('state_games') ? JSON.parse(utils.getCookie('state_games')) : undefined,
  /* eslint-disable no-undef */
  state_music: utils.getCookie('state_music') ? JSON.parse(utils.getCookie('state_music')) : undefined,
  /* eslint-disable no-undef */
  state_search: utils.getCookie('state_search') ? JSON.parse(utils.getCookie('state_search')) : undefined,
  /* eslint-disable no-undef */
  state_settings: utils.getCookie('state_settings') ? JSON.parse(utils.getCookie('state_settings')) : undefined,
  state_filters: utils.getCookie('state_filters') ? JSON.parse(utils.getCookie('state_filters')) : undefined,
  /* eslint-disable no-undef */
  state_myapps: utils.getCookie('state_myapps') ? JSON.parse(utils.getCookie('state_myapps')) : undefined
};

export default function stateReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.UPDATE_STATE_HOME:
      utils.setCookie('state_home', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_home: action.data });
    case CONSTANTS.UPDATE_STATE_APPS:
      utils.setCookie('state_apps', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_apps: action.data });
    case CONSTANTS.UPDATE_STATE_APPCATEGORY:
      utils.setCookie('state_appCategory', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_appCategory: action.data });
    case CONSTANTS.UPDATE_STATE_MOVIES:
      utils.setCookie('state_movies', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_movies: action.data });
    case CONSTANTS.UPDATE_STATE_MOVIECATEGORY:
      utils.setCookie('state_movieCategory', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_movieCategory: action.data });
    case CONSTANTS.UPDATE_STATE_TVSHOWS:
      utils.setCookie('state_tvshows', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_tvshows: action.data });
    case CONSTANTS.UPDATE_STATE_TVSHOWCATEGORY:
      utils.setCookie('state_tvshowCategory', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_tvshowCategory: action.data });
    case CONSTANTS.UPDATE_STATE_GAMES:
      utils.setCookie('state_games', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_games: action.data });
    case CONSTANTS.UPDATE_STATE_MUSIC:
      utils.setCookie('state_music', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_music: action.data });
    case CONSTANTS.UPDATE_STATE_SEARCH:
      utils.setCookie('state_search', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_search: action.data });
    case CONSTANTS.UPDATE_STATE_SETTINGS:
      utils.setCookie('state_settings', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_settings: action.data });
    case CONSTANTS.UPDATE_STATE_FILTERS:
      utils.setCookie('state_filters', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_settings: action.data });
    case CONSTANTS.UPDATE_STATE_MYAPPS:
      utils.setCookie('state_myapps', JSON.stringify(action.data), 3);
      return Object.assign({}, state, { state_myapps: action.data });
    default:
      return state;
  }
}
