import CONSTANTS from './constants';

const initialState = {
  /* eslint-disable no-undef */
  theme: FxmModule.getStoredData('theme') ? FxmModule.getStoredData('theme') : 'first-theme'
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.UPDATE_THEME:
      FxmModule.store('theme', action.data);
      return Object.assign({}, state, { theme: action.data });
    default:
      return state;
  }
}
